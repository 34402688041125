<template>
  <section>
    <!-- Table Container Card -->
    <b-card
      header-tag="header"
      border-variant="info"
      header-bg-variant="light-info"
      header-class="p-1"
      class="border mb-0 mt-1"
      no-body
      style="max-height: 70vh"
    >
      <template #header>
        <h5 class="m-0 text-uppercase font-weight-bolder">
          {{ $t('agency.employees.title') }}
        </h5>
      </template>

      <!-- Table Content -->
      <b-table
        ref="refEmployeeListTable"
        style="max-height: 55vh"
        :sticky-header="true"
        class="position-relative"
        :items="agenciesData.employees"
        responsive
        :fields="tableFieldsEmployees"
        primary-key="id"
        show-empty
        :empty-text="$t('noMatchingResult')"
        :sort-desc.sync="isSortDirDesc"
        :sort-by.sync="sortBy"
        no-border-collapse
        small
      >
        <template
          v-for="column in tableFieldsEmployees"
          #[`head(${column.key})`]="data"
        >
          <span
            :key="column.label"
            class="text-dark text-nowrap"
          >
            {{ $t(`agency.employees.columns.${data.label}`) }}
          </span>
        </template>

        <template #cell(no)="data">
          <span class="font-weight-bold">{{ data.index + 1 }}</span>
        </template>

        <template #cell(employeeCode)="data">
          <div class="text-nowrap">
            <b-link
              :disabled="!canAccess('employee.detailEmployee')"
              :to="{
                name: 'apps-employees-edit',
                params: { id: data.item.id },
              }"
              class="font-weight-bold d-block text-nowrap text-info"
            >
              <span
                class="align-text-top text-capitalize font-weight-bold"
              >
                {{ data.item.employeeCode }}
              </span>
            </b-link>
          </div>
        </template>

        <template #cell(account)="data">
          <h5 class="font-weight-bold align-text-top mb-0">
            <feather-icon
              size="14"
              icon="UserIcon"
              class=""
            />
            {{ data.item.username || 'N/A' }}
          </h5>
          <div>
            <small class="align-items-center d-flex flex-nowrap">
              <feather-icon
                size="14"
                icon="MailIcon"
                class="mr-25"
              />
              <span>{{ data.item.emailAddress || 'N/A' }}</span>
            </small>
          </div>
          <div>
            <small class="align-items-center d-flex flex-nowrap">
              <feather-icon
                size="14"
                icon="PhoneCallIcon"
                class="mr-25"
              />
              {{ data.item.phoneNumber || 'N/A' }}
            </small>
          </div>
        </template>

        <template #cell(information)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="50"
                :src="data.item.avatar"
                :text="avatarText(`${data.item.lastName} ${data.item.firstName}`)"
                :variant="`light-${resolveEmployeeTypeVariant(data.item.type)}`"
              />
            </template>
            <div class="d-flex flex-column justify-content-center align-items-start">
              <h5
                class="mb-0 text-nowrap"
              >
                {{ data.item.lastName }} {{ data.item.firstName }}
              </h5>
              <small class="d-flex justify-content-start align-items-center">
                <feather-icon
                  size="14"
                  icon="AwardIcon"
                  class="mr-25"
                />
                <span :class="`text-${resolveEmployeeTypeVariant(data.item.type)} font-weight-bold`">
                  {{ $te(resolveEmployeeTypeTitle(data.item.type)) ? $t(resolveEmployeeTypeTitle(data.item.type)) : data.item.type }}
                </span>
              </small>
              <small class="d-flex justify-content-start align-items-center">
                <feather-icon
                  size="14"
                  icon="TagIcon"
                  class="mr-25"
                />
                <small class="">{{ $t(resolveGender(data.item.gender, 'value', 'label')) }} </small>
              </small>
            </div>
          </b-media>
        </template>

        <template #cell(isActive)="data">
          <div class="d-flex-center">
            <b-form-checkbox
              v-model="data.item.isActive"
              class="mr-0 custom-control-success"
              name="check-button"
              switch
              inline
              :disabled="data.item.isActive ? !canAccess('employee.activeEmployee') : !canAccess('employee.deactiveEmployee')"
              @click.native.prevent="changeActive(data.item)"
            />
          </div>
        </template>

        <template #cell(createdAt)="data">
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-info"
                size="34"
              >
                <feather-icon
                  icon="ClockIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0 text-nowrap">
                {{ dateTime(data.item.createdAt) }}
              </h6>
              <small class="text-nowrap">
                {{
                  data.item.createdBy
                    ? `${data.item.createdBy.firstName} ${data.item.createdBy.lastName}`
                    : ''
                }}</small>
            </b-media-body>
          </b-media>
        </template>

        <template #cell(updatedAt)="data">
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-info"
                size="34"
              >
                <feather-icon
                  icon="EditIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0 text-nowrap">
                {{ dateTime(data.item.updatedAt) }}
              </h6>
              <small class="text-nowrap">
                {{
                  data.item.updatedBy
                    ? `${data.item.updatedBy.firstName} ${data.item.updatedBy.lastName}`
                    : ''
                }}</small>
            </b-media-body>
          </b-media>
        </template>

        <template #cell(action)="data">
          <div class="d-flex-center">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="info"
              class="btn-icon p-50 text-nowrap"
              @click="handleShowModalChangePassword(data.item)"
            >
              {{ $t(`agency.employees.changePassword`) }}
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>

    <ModalChangePasswordEmployee
      :employees.sync="dataEmployees"
      @refetchAgency="refetchAgency"
    />
  </section>
</template>

<script>
import {
  BFormCheckbox,
  BCard,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaBody, BMediaAside,
  BButton,
} from 'bootstrap-vue'
import {
  computed,
  onUnmounted,
  ref,
} from '@vue/composition-api'

import {
  genderOptions,
  sizePerPageOptions,
  statusOptions,
  typeOfEmployeeOptions,
  resolveGender,
} from '@/constants/selectOptions'
import store from '@/store'

import { avatarText, dateTime } from '@core/utils/filter'

import employeeStoreModule from '@employee/employeeStoreModule'
import useEmployeeHandle from '@employee/useEmployeeHandle'

import useAgenciesHandle from '@agencies/useAgenciesHandle'

import useToast from '@useToast'

export default {
  components: {
    BMediaBody,
    BMediaAside,
    BFormCheckbox,
    BCard,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BButton,

    ModalChangePasswordEmployee: () => import('@agencies/agencies-edit/ModalChangePasswordEmployee.vue'),
  },
  props: {
    agenciesData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const EMPLOYEE_APP_STORE_MODULE_NAME = 'app-employee'

    // Register module
    if (!store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) {
      store.registerModule(EMPLOYEE_APP_STORE_MODULE_NAME, employeeStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(EMPLOYEE_APP_STORE_MODULE_NAME)
      }
    })

    function refetchAgency() {
      emit('refetchAgency')
    }

    const { toastError } = useToast()

    async function changeActive(employee) {
      const confirmResult = await this.$bvModal.msgBoxConfirm(this.$t('modal.changeStatus'), {
        title: this.$t('modal.confirm'),
        size: 'sm',
        okVariant: 'info',
        okTitle: this.$t('modal.yes'),
        cancelTitle: this.$t('modal.no'),
        cancelVariant: 'outline-danger',
        hideHeaderClose: true,
        centered: true,
      })

      if (confirmResult) {
        try {
          emit('update:loading-prop', true)
          if (employee.isActive) {
            await this.updateDeactive(employee.id)
          } else {
            await this.updateActive(employee.id)
          }
        } catch (error) {
          console.error(error)
        } finally {
          await refetchAgency()
          emit('update:loading-prop', false)
        }
      }
    }

    const {
      // Customer
      tableEmployees,
    } = useAgenciesHandle()

    const {
      updateActive,
      updateDeactive,

      resolveEmployeeTypeVariant,
      resolveEmployeeTypeTitle,

      sortBy,
      isSortDirDesc,
      refEmployeeListTable,
    } = useEmployeeHandle()

    const tableFieldsEmployees = computed(() => [...tableEmployees, { label: 'action', key: 'action' }])
    const meData = computed(() => store.getters['userStore/getMeData'])
    const dataEmployees = ref(null)

    async function handleShowModalChangePassword(employee) {
      const validType = ['ADM', 'OPE', 'SE'].includes(meData.value?.type)
      if (!validType) {
        toastError({
          title: 'messagesList.error',
          content: 'agency.employees.OnlyADMandOPEandSErole',
        })
        return
      }

      const isParentAgency = meData.value?.agency?.id === props.agenciesData?.parentAgency?.id
      if (!isParentAgency) {
        toastError({
          title: 'messagesList.error',
          content: 'agency.employees.OnlyAgencyHighUp',
        })
        return
      }

      dataEmployees.value = employee
      await new Promise(resolve => setTimeout(resolve, 200))
      this.$bvModal.show('id-modal-agency-change-password-employee-account')
    }

    return {
      // Sidebar
      changeActive,
      updateDeactive,
      updateActive,

      dateTime,
      sortBy,
      isSortDirDesc,
      refEmployeeListTable,

      // Filter
      avatarText,

      // UI
      resolveEmployeeTypeVariant,
      resolveEmployeeTypeTitle,
      resolveGender,

      // select Options
      typeOfEmployeeOptions,
      genderOptions,
      statusOptions,
      sizePerPageOptions,

      // Customer
      tableFieldsEmployees,
      dataEmployees,
      handleShowModalChangePassword,
      refetchAgency,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
